import { render, staticRenderFns } from "./NewCognitiveLevelDimensionsST.vue?vue&type=template&id=5331b702&scoped=true"
import script from "./NewCognitiveLevelDimensionsST.vue?vue&type=script&lang=js"
export * from "./NewCognitiveLevelDimensionsST.vue?vue&type=script&lang=js"
import style0 from "./NewCognitiveLevelDimensionsST.vue?vue&type=style&index=0&id=5331b702&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5331b702",
  null
  
)

export default component.exports