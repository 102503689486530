var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{attrs:{"bordered":"","small":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-class"},[_vm._v(" NIVEL COGNITIVO ")]),_c('b-th',{staticClass:"th-class"},[_vm._v(" CANTIDAD DE "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `${_vm.$getVisibleNames(
                'manual.indicador_de_logro',
                true,
                'Indicadores de Logros'
              ).toUpperCase()}`
            ),expression:"\n              `${$getVisibleNames(\n                'manual.indicador_de_logro',\n                true,\n                'Indicadores de Logros'\n              ).toUpperCase()}`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v(" (IL) ")])]),_c('b-th',{staticClass:"th-class"},[_c('span',[_vm._v(" CANTIDAD DE "+_vm._s(_vm.$getVisibleNames( "manual.dimension", false, "Dimensión" ).toUpperCase())+" ")]),(_vm.allows_crud && _vm.rubric && !_vm.rubric.is_locked)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              `Crear ${_vm.$getVisibleNames(
                'manual.dimension',
                false,
                'Dimensión'
              )}`
            ),expression:"\n              `Crear ${$getVisibleNames(\n                'manual.dimension',\n                false,\n                'Dimensión'\n              )}`\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"m-0 ml-2 p-0 text-white",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-created-rubric-criteria-${_vm.test.id}`)}}}):_vm._e()],1),_c('b-th',{staticClass:"th-class"},[_vm._v("PODERACIÓN")])],1)],1),_c('b-tbody',_vm._l((_vm.cognitiveLevelList),function(cognitive_level,index){return _c('b-tr',{key:'NewCognitiveLevelDimensionsST:' + index + '-' + _vm.test.id},[_c('b-td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
            `${
              cognitive_level.id != null
                ? cognitive_level.level + '. ' + cognitive_level.name
                : ''
            }`
          ),expression:"\n            `${\n              cognitive_level.id != null\n                ? cognitive_level.level + '. ' + cognitive_level.name\n                : ''\n            }`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"text-center",class:{ 'underline-cell': cognitive_level.id != null }},[_vm._v(_vm._s(cognitive_level.level))]),_c('b-td',{staticClass:"text-center"},[(_vm.countUniqueIL(cognitive_level.rubric_criterias) > 0)?[_vm._v(" "+_vm._s(_vm.countUniqueIL(cognitive_level.rubric_criterias))+" ")]:[_vm._v("-")]],2),_c('td',{staticClass:"text-center"},[(cognitive_level.rubric_criterias.length > 0)?_c('div',[_c('div',{staticClass:"underline-cell"},[_c('span',{attrs:{"id":`cl-rubric-criteria-cell-${cognitive_level.id}-${_vm.test.id}`}},[_vm._v(" "+_vm._s(cognitive_level.rubric_criterias.length)+" ")])]),_vm._l((cognitive_level.rubric_criterias),function(rubric_criteria){return [_c('b-modal',{key:`modal-update-rubric-instance-${_vm.test.id}-${rubric_criteria.id}-${cognitive_level.id}`,attrs:{"id":`modal-update-rubric-instance-${_vm.test.id}-${rubric_criteria.id}-${cognitive_level.id}`,"title":'Modificar ' +
                  _vm.$getVisibleNames('manual.dimension', false, 'Dimensión'),"hide-footer":"","size":"xl"}},[_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"RubricCriteria":rubric_criteria,"order":rubric_criteria.order,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"show_delete_button":true},on:{"updated":function($event){return _vm.$bvModal.hide(
                      `modal-update-rubric-instance-${_vm.test.id}-${rubric_criteria.id}-${cognitive_level.id}`
                    )},"deleted":function($event){return _vm.$bvModal.hide(
                      `modal-update-rubric-instance-${_vm.test.id}-${rubric_criteria.id}-${cognitive_level.id}`
                    )}}})],1)]}),_c('b-popover',{attrs:{"target":`cl-rubric-criteria-cell-${cognitive_level.id}-${_vm.test.id}`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Niveles de Logro" ))+" ")]},proxy:true}],null,true)},_vm._l((cognitive_level.rubric_criterias),function(rubric_criteria){return _c('div',{key:rubric_criteria.id +
                  '-' +
                  cognitive_level.id +
                  '-' +
                  _vm.test.id,staticClass:"d-flex"},[_c('div',{staticClass:"w-100"},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.dimension", false, "Dimensión"))+" N° "+_vm._s(rubric_criteria.order)+" ")],1),(_vm.allows_crud && _vm.rubric && !_vm.rubric.is_locked)?_c('div',{staticClass:"d-flex"},[_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `Editar ${_vm.$getVisibleNames(
                        'manual.dimension',
                        false,
                        'Dimensión'
                      )}`
                    ),expression:"\n                      `Editar ${$getVisibleNames(\n                        'manual.dimension',\n                        false,\n                        'Dimensión'\n                      )}`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                        `modal-update-rubric-instance-${_vm.test.id}-${rubric_criteria.id}-${cognitive_level.id}`
                      )}}})],1):_vm._e()])}),0)],2):[_vm._v("-")]],2),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.totalCountWeighingCL(cognitive_level.rubric_criterias))+" ")])],1)}),1),_c('b-tfoot',[_c('b-tr',{staticClass:"cl-tfoot"},[_c('b-td',{staticClass:"text-center"},[_vm._v("TOTAL")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.countUniqueIL(_vm.rubricCriteriaList))+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.rubricCriteriaList.length))]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.rubricCriteriaList.reduce( (acc, x) => acc + parseFloat(x.weighing), 0 ))+"% ")])],1)],1)],1),_c('b-modal',{attrs:{"id":`modal-created-rubric-criteria-${_vm.test.id}`,"title":`Crear ${_vm.$getVisibleNames(
      'manual.dimension',
      false,
      'Dimensión'
    )}`,"size":"xl","hide-footer":""}},[(_vm.rubric)?_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.rubricCriteriaList.length > 0
          ? _vm.rubricCriteriaList[_vm.rubricCriteriaList.length - 1].order + 1
          : 1},on:{"created":function($event){return _vm.$bvModal.hide(`modal-created-rubric-criteria-${_vm.test.id}`)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }